import useIntervalRefresher from '@/mixins/useIntervalRefresher';
import useUser from '@/mixins/useUser';
import {
  ContractDetail, ContractUpdateNotes, NewContractRequest
} from '@/models/Contracts';
import { BaseUserWithRole } from '@/models/User';
import {
  getAllContracts, getContract, postNewContract, addNotesToContract,
  declineContract,
  acceptContract,
  reopenContract,
  deleteContract,
  deleteContractDocument
} from '@/services/ContractService';
import useMessengerState from '@/state/messenger/messengerState';
import { createGlobalState, useAsyncState, useEventBus } from '@vueuse/core';
import {
  computed, reactive, ref
} from 'vue';

const useContract = createGlobalState(() => {
  const events = useEventBus('contracts');

  const { userId } = useUser('self');

  const openContractId = ref<string | null>(null);
  const contractDetail = ref<ContractDetail | null>(null);
  const fetchContractDetail = async (contractId: string) => {
    openContractId.value = contractId;
    contractDetail.value = await getContract(contractId);
  };

  const isUserClient = computed(() => userId.value === (contractDetail.value?.client?.user?.userId ?? contractDetail.value?.client?.company?.rootUserId ?? ''));
  const isUserContractor = computed(() => userId.value === (contractDetail.value?.contractor?.user?.userId ?? contractDetail.value?.contractor?.company?.rootUserId ?? ''));

  const contractPartnerUser = computed(() => {
    if (contractDetail.value) {
      const contractorUser = useUser((contractDetail.value.contractor.user ?? contractDetail.value.contractor) as BaseUserWithRole);
      const clientUser = useUser((contractDetail.value.client.user ?? contractDetail.value.client) as BaseUserWithRole);
      if (isUserClient.value) {
        return contractorUser;
      }
      if (isUserContractor.value) {
        return clientUser;
      }
    }
    return null;
  });

  const { createNewChat, isOpened } = useMessengerState();
  const openChatWithContractPartner = async () => {
    if (contractPartnerUser.value) {
      isOpened.value = true;
      await createNewChat(contractPartnerUser.value.user.value);
    }
  };

  const openContractDocumentId = ref<string | null>(null);
  const toggleOpenContractDocument = (documentId: string) => {
    if (openContractDocumentId.value === documentId) {
      openContractDocumentId.value = null;
    } else {
      openContractDocumentId.value = documentId;
    }
  };

  const deleteOpenContractDocument = () => {
    if (!openContractDocumentId.value || !contractDetail.value) return;
    deleteContractDocument(contractDetail.value.contractId, openContractDocumentId.value);
    contractDetail.value.documents = contractDetail.value.documents.filter((doc) => doc.id !== openContractDocumentId.value);
    openContractDocumentId.value = null;
  };

  const setContractDocumentSigature = (documentId: string, signDocument: boolean) => {
    if (!contractDetail.value) return;
    const document = contractDetail.value.documents.find((doc) => doc.id === documentId);
    if (!document) return;
    if (isUserClient.value) document.signedByClient = signDocument;
    if (isUserContractor.value) document.signedByContractor = signDocument;
  };

  const submitContractNotes = async (notes: ContractUpdateNotes) => {
    if (!contractDetail.value) return;
    if (isUserContractor.value) {
      notes.contactPerson = undefined;
      notes.department = undefined;
      notes.costCenter = undefined;
    }
    await addNotesToContract(contractDetail.value.contractId, notes);
  };

  const abortContractProcess = async () => {
    if (!contractDetail.value) return;
    await declineContract(contractDetail.value.contractId);
    if (openContractId.value) {
      fetchContractDetail(openContractId.value);
    }
  };

  const finalizeContractProcess = async () => {
    if (!contractDetail.value) return;
    await acceptContract(contractDetail.value.contractId);
    if (openContractId.value) {
      fetchContractDetail(openContractId.value);
    }
  };

  const reopenContractProcess = async () => {
    if (!contractDetail.value) return;
    await reopenContract(contractDetail.value.contractId);
    if (openContractId.value) {
      fetchContractDetail(openContractId.value);
    }
  };

  const deleteContractProcess = async () => {
    if (!contractDetail.value) return;
    await deleteContract(contractDetail.value.contractId);
    if (openContractId.value) {
      fetchContractDetail(openContractId.value);
    }
  };

  return {
    events,
    openContractId,
    contractDetail,
    isUserClient,
    isUserContractor,
    contractPartnerUser,
    openChatWithContractPartner,
    fetchContractDetail,
    submitContractNotes,
    openContractDocumentId,
    toggleOpenContractDocument,
    deleteOpenContractDocument,
    setContractDocumentSigature,
    abortContractProcess,
    finalizeContractProcess,
    reopenContractProcess,
    deleteContractProcess
  };
});

export default useContract;
