import useIntervalRefresher from '@/mixins/useIntervalRefresher';
import { NewContractRequest } from '@/models/Contracts';
import {
  getAllContracts, postNewContract
} from '@/services/ContractService';
import { createGlobalState, useAsyncState } from '@vueuse/core';
import { reactive } from 'vue';

const useContractOverview = createGlobalState(() => {
  const { state: allContracts, execute: fetchContracts, isLoading: isContractOverviewLoading } = useAsyncState(getAllContracts, []);

  const { stop: stopContractOverviewRefresh, reset: resetContractOverviewRefresh, resume: resumeContractOverviewRefresh } = useIntervalRefresher(fetchContracts, {
    beginningInterval: 10000, endingInterval: 60000, name: 'contractsOverview', timeSpan: 120000
  });

  const newContractProperties = reactive<NewContractRequest>({
    client: undefined,
    contractPartnerId: '',
    message: ''
  });

  const submitNewContract = async () => {
    const contract = await postNewContract(newContractProperties);
    allContracts.value.push(contract);
    newContractProperties.client = undefined;
    newContractProperties.contractPartnerId = '';
    newContractProperties.message = '';
    return contract;
  };

  return {
    fetchContracts,
    isContractOverviewLoading,
    allContracts,
    newContractProperties,
    stopContractOverviewRefresh,
    resetContractOverviewRefresh,
    resumeContractOverviewRefresh,
    submitNewContract,
  };
});

export default useContractOverview;
